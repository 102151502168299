import React from 'react'

import Popover from '@material-ui/core/Popover'

export default function Legend(props) {
  const {statistics, filter } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const filterList = (
    filter[0] === "정치성향별" ? [['보수', 'ex-r'], ['중도보수', 'r'], ['중도', 'n'], ['중도진보', 'l'], ['진보', 'ex-l'], ['잘 모름/대답을 원치 않음', 'not']]
      : filter[0] === "성별" ? [['여성', 'female'], ['남성', 'male'], ['그 외', 'un'], ['대답을 원치 않음', 'not']]
        : [['10대', 'g10'], ['20대', 'g20'], ['30대', 'g30'], ['40대', 'g40'], ['50대', 'g50'], ['60대', 'g60'], ['70대 이상', 'g70']]
  )
  const statsKey = {
    '정치성향별': 'stanceCount',
    '성별': 'genderCount',
    '연령대별': 'ageCount'
  }[filter[0]]

  const openPopover = (event) => {
    console.log(event.currentTarget)
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <div onClick={openPopover}>
        <span className={"dashed"}>별 색깔 선택</span>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(event) => { setAnchorEl(null)}}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        <div className="filter-group legend">
          <div className="filter-text">
            총 {statistics.opinionCount}명 중
          </div>
          {
            filterList.map(filter => {
              return (
                <div className="filter-item" key={filter[0]}>
                  <div className={`${filter[1]} circular filter`} />
                  <div className={`filter-text`}>
                    {filter[0]} {statistics[statsKey][filter[0]]}명
                  </div>
                </div>
              )
            })
          }
        </div>
      </Popover>
    </div>
  )
}