import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Button from '../../components/Button';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    const { setMediaFilter, saveAPIToken } = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        token: saveAPIToken
    }
  }

const textDesktop = `
1. 연구의 배경 및 목적

사회 문제에 대한 다양한 의견을 공유하고 토론할 수 있는 공론장은 민주주의의 관점에서 매우 중요합니다. 하지만, 지금 온라인 공간에서는 다른 의견을 경청하고 인정하는 건전한 토론보다, 같은 의견을 가진 사람끼리 뭉쳐 다른 의견을 배척하고 비난하는 경우를 더욱 자주 목격합니다. 많은 연구자들은 비슷한 생각을 가진 사람들의 의견을 위주로 보여주는 많은 온라인 플랫폼의 디자인을 이러한 문제의 원인으로 지목합니다. 
본 연구에서는 온라인에서 사회 이슈에 대한 다른 의견을 존중할 수 있도록 돕는 의견 공유 플랫폼을 설계하고 검증하고자 합니다. 구체적으로, 본 연구에서는 두 가지 요소를 도입한 플랫폼을 설계하고 검증하고자 합니다. 첫 번째 요소는 “다름”에 기반한 의견 추천입니다. 사용자가 읽고 있는 의견의 실제 내용을 자연언어처리 기술을 이용하여 분석, 가장 다른 단어를 사용한 의견, 혹은 비슷한 단어를 사용하였지만 입장이 다른 의견 등 여러 기준에서 읽고 있는 의견과 다른 의견을 발견할 수 있는 요소를 도입하려 합니다. 
두 번째 요소는 의견과 함께 의견 작성자에 대한 정보를 제공하는 것입니다. 의견 작성자의 나이, 성별, 정치 성향 등을 포함한 다양한 특징을 제공하여 다른 의견을 가진 사람을 더 잘 이해할 수 있도록 돕고자 합니다. 

2. 예상 참여기간 및 본 시험에 참여하는 대략의 전체 피험자 수

본 연구 참여에 예상되는 소요 시간은 개인당 약 1시간입니다. 본 연구에는 약 60명이 참여합니다.

3. 본 연구에 참여하게 됨으로써 받게 되는 검사 및 절차
본 연구에 적합한 피험자로 선정되실 경우, 정해진 실험실에서 연구진이 개발한 웹 인터페이스를 이용하게 됩니다. 인터페이스 이용 과정에서, 본 연구진이 제시한 두 가지 사회 이슈에 대한 의견 및 실험 참여자님의 연령대, 성별, 직업과 정치 성향 등 의견에 영향을 끼칠 수 있는 개인 정보를 입력하게 됩니다. 그 뒤, 인터페이스를 이용하면서 자유롭게 이슈에 대한 다른 사람들의 의견을 살펴보게 됩니다. 인터페이스 사용 기록은 연구용 서버에 저장됩니다. 인터페이스 이용 후 인터페이스의 사용성에 대한 설문조사 및 인터뷰에 응답하시게 됩니다. 

4. 이 연구에 참여함으로써 기대되는 이익
해당 인터페이스가 다루는 사회적·정치적 이슈에 대한 다양한 의견에 대한 새로운 접근을 경험하실 수 있습니다. 또한, 이 연구를 통해 얻은 정보는 추후 사회 이슈 논의를 위한 온라인 플랫폼 연구에 도움이 됩니다. 

5. 신분의 비밀보장 및 개인정보 보호(자료의 열람 권한 설정 및 보관, 관리, 폐기, 임상시험 결과 발표 시 피험자의 신원 보호)

개인정보 보호를 위해 모든 데이터는 익명으로 처리되며, 추후 연구결과를 발표하게 될 시에 결과 내용에 연구 대상자의 신원이 드러나는 식별 정보는 포함되지 않을 것입니다. 특히, 카카오 계정 연동으로 받아오는 정보는 이메일을 제외한 어떠한 정보도 저장하지 않습니다.

인터페이스 사용 중 입력하신 참여자님의 사회 이슈에 대한 의견 및 의견과 연관된 일부 정보 (연령대, 성별, 정치 성향 및 직업)는 실험 과정 중 다른 실험 참여자에게 인터페이스 사용 경험의 일부로 보여질 수 있습니다. 인터페이스를 통해 공개되는 정보는 개인 식별이 가능한 정보를 포함하지 않을 것이며, 사회 이슈에 대한 의견을 제외한 개인정보를 공개하지 않을 것을 요구할 시 개인정보를 비공개 처리할 것입니다. 따라서, 주관식 의견 작성 시 식별 가능한 개인정보를 포함하지 않도록 유의해주시길 부탁드립니다.

인터페이스에 대한 사용성 설문 결과 및 인터페이스 사용 기록 정보는 외부에서 접근이 제한된 서버에 저장되며, 참여 연구원에 의해서만 처리 및 가공될 것이고, 오직 연구 결과를 도출하는 용도로만 이용될 것입니다. 만일 데이터 수집 기간동안 참가자로부터 수집 중단 및 데이터 이용 중단 요구가 있을 경우, 참가를 중단하고 해당 참가자의 데이터는 모두 파기할 것입니다.

6. 연구와 관련된 손상이 발생하였을 경우 피험자에게 주어질 보상이나 치료방법

본 연구는 연구 참가자에게 어떤 조작을 가하거나 육체적인 활동을 요구하지 않습니다. 만약 이 연구에 의한 손상이 발생했다고 판단될 경우 그에 상응하는 보상을 할 것입니다.

7. 연구 참가 거부 및 중단

본 연구에 참여 여부를 결정하는 것은 참여자에게 달려있습니다. 참여자님은 언제든지 연구에 참여하지 않기로 결정할 수 있고 또한 시험 참여를 그만둘 수 있습니다. 참여자님께서 본 연구에 참여하지 않아도 아무런 불이익을 받지 않으며, 수집된 데이터의 이용 중단을 요구할 경우 연구진은 수집 데이터 일체를 파기할 것입니다.

8. 본 연구를 위해 피험자가 준수해야 하는 사항
본 연구를 오염시키기 위한 악의적인 목적으로 인터페이스 사용에 임하지 말아야 합니다. 연구원이 피험자가 악의적인 목적을 가지고 있다고 판단한다면, 관련 데이터를 파기할 수 있습니다.

9. 연구에 대한 추가적인 정보를 얻고자 하거나 연구와 관련이 있는 손상이 발생한 경우에 접촉해야 하는 사람과 연락처

본 연구의 참여와 관련하여 문의 및 불만 사항이 있으신 경우에는 언제든지 연구진에게 연락하여 주시기 바랍니다.

연구 책임자:
김주호, 카이스트 전산학부 부교수, juhokim@kaist.ac.kr 
연락 담당자:
김현우, 카이스트 전산학부, khw0726@kaist.ac.kr
본인은 이 동의서를 읽고 이해하였으며 본인은 자발적으로 본 연구에 참여함을 확인합니다.

 또한, 본인은
   1. 본인과 연구자 및 KAIST 사이에 본인의 연구 참여 결정에 영향을 줄 수 있는 어떠한 관계도 없고,
   2. 인지 기능에 문제가 있지 아니함을 확인합니다
   `

const textMobile = textDesktop //'귀하는 서비스 내에서 적용되는 모든 정책을 준수해야 합니다.\n플랫폼 서비스의 오용을 삼가시기 바랍니다. 예를 들어 서비스를 방해하거나 플랫폼이 제공하는 인터페이스 및 안내사항 이외의 다른 방법을 사용하여 액세스를 시도하지 않아야 합니다. 귀하는 관련 수출 및 재수출 통제 법규 및 규정 등 오직 법률상 허용되는 범위에서만 플랫폼 서비스를 이용할 수 있습니다. 귀하가 플랫폼 약관이나 정책을 준수하지 않거나 플랫폼이 부정행위 혐의를 조사하고 있는 경우, 플랫폼 서비스 제공이 일시 중지 또는 중단될 수 있습니다.'

class ConsentScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundImage: ''
        }
        this.consentAgreeHandler = this.consentAgreeHandler.bind(this);
    }
    componentDidMount() {
        this.setState({backgroundImage: document.body.style.backgroundImage});
        document.body.style.backgroundImage = 'none';
        document.body.style.color = '#000000';
    }

    componentWillUnmount() {
        const { backgroundImage } = this.state;
        document.body.style.backgroundImage= backgroundImage;
        document.body.style.color= '#ffffff';
    }

    consentAgreeHandler() {
        const { token } = this.props;
        if (token) {
            fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                method: 'put',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    consentAgreed: true
                })
            })
        }
        this.props.history.push('/AnswerIssue')
    }

    render() {
        const { isMobile } = this.props;
        return (
            <div className="wrapper">
                <Header/>
                <div className="container">
                    {/* <div className="title">
                        '별별생각’은 보다 더 다양한 사람들에게서 보다 더 다양한 의견을 들을 수 있게, 
                        우리가 보는 세상을 더 넓게 만들기 위한 시스템입니다. 여러 이슈에 대해 의견을 수집하고, 
                        다양한 사람들의 의견을 보면서 내가 생각하던 것이 전부가 아니라는 것, 
                        세상에는 정말 ‘별별생각’이 다 있다는 것을 알아 가세요.
                    </div> */}
                    <div className="title text-bold">
                        ‘별별생각‘에 참여하신 여러분의 소중한 데이터는 연구 목적으로 활용됩니다.
                        <br></br>
                         아래 설명을 읽고 동의하시면 ‘다음’을 눌러 주세요.
                    </div>
                    <div className="description">
                        <div className="text-center">
                            인간 대상 연구 피험자 동의서
                        </div>
                        {isMobile ?
                        textMobile.split('\n').map((item, i) => {
                            return <div key={i}><p>{item}</p><br/></div>
                        })
                        :textDesktop.split('\n').map((item, i) => {
                            return <div key={i}><p>{item}</p><br/></div>
                        })
                        }
                    </div>
                </div>
                <div className="next">
                    <Button type="button-gray button text-bold" content="다음" onClick={this.consentAgreeHandler}/>
                </div>
            </div>
        )
    }
}

ConsentScreen.propTypes = {
    isMobile: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(ConsentScreen);