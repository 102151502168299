import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/Button';

import { connect } from 'react-redux';
import { saveProfile, saveAPIToken } from '../../actions';

import logo from '../../assets/img/logo1_tight.png'
import kixlab from '../../assets/img/kixlab.png'
import sdflogo from '../../assets/img/sdf_new.png'
import kaist from '../../assets/img/kaist.png'
import sbs from '../../assets/img/sbs.png'
import EventDialog from '../../components/EventDialog'
import ReactGA from 'react-ga'
const mapStateToProps = (state) => {
    const { saveProfile, saveAPIToken } = state;
    return {
        profile: saveProfile,
        token: saveAPIToken
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        save_profile: (profile) => dispatch(saveProfile(profile)),
        save_token: (token) => dispatch(saveAPIToken(token))
    }
}

class HomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            name: '',
            email: '',
            access_token: '',
            refresh_token: ''
        }
        this.login = this.login.bind(this);
        this.proceed = this.proceed.bind(this);
        this.successHandler = this.successHandler.bind(this);
        this.createDummyUser = this.createDummyUser.bind(this)
        this.alertText = `카카오톡이나 페이스북 등 앱에서 접속하신 경우 카카오 계정 로그인 등 일부 기능이 동작하지 않을 수 있습니다. \n오른쪽 위 메뉴 (안드로이드) 혹은 오른쪽 아래 공유하기 (iOS) 에서 다른 브라우저로 접속해주세요!`
    }

    createDummyUser() {
        const emailId = Math.random().toString(36).substring(2);
        const kakaoId = Math.random().toString(9).substring(7);
        const email = emailId + '@dummy.com';
        // console.log(email, kakaoId);
        fetch(`${process.env.REACT_APP_URL}/dev/users`, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                email: email, 
                kakaoId: kakaoId,
            })
        })
        .then((res) => res.json())
        .then((response) => {
            this.props.save_token(response.token);
            ReactGA.set({ userId: kakaoId });
            localStorage.setItem('dummy', true);
            this.props.history.push('/consent');
        }, (error) => {
            if (error) {
                console.log(error);
            }
        })
    }

    proceed() {
        const { token } = this.props
        const UA = window.navigator.userAgent.toLowerCase()
        if (navigator.userAgent.includes('wv') ||
                (/iphone|ipod|ipad/.test(UA) && !/safari/.test(UA))) {
                alert(this.alertText) 
            }
        if (token) {
            fetch(`${process.env.REACT_APP_URL}/dev/users/token`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => res.json())
            .then(user => {
                if (user && user.consentAgreed && user.numOpinions && user.numOpinions >= 2) {
                    this.props.history.push('/NewComplete')
                } else if (user && user.consentAgreed) {
                    this.props.history.push('/AnswerIssue')
                } else if (user) {
                    this.props.history.push('/consent')
                } else {
                    this.createDummyUser()
                }
            })
        } else {
            this.createDummyUser()
        }
    }

    login() {
        const UA = window.navigator.userAgent.toLowerCase()
        if (navigator.userAgent.includes('wv') ||
                (/iphone|ipod|ipad/.test(UA) && !/safari/.test(UA))) {
                alert(this.alertText) 
            }
        window.Kakao.Auth.login({
            scope: 'account_email',
            success: (res) => {
                localStorage.setItem('dummy', false);
                this.successHandler(res)
            },
            fail: function(error) {
                console.log(error);
            }
          });
    }

    successHandler(res) {
        const dummy = localStorage.getItem('dummy')
        const { token } = this.state
        console.log(dummy)
        ReactGA.set({ userId: res.id });
        this.setState({
            access_token: res.access_token,
            refresh_token: res.refresh_token,
        });
        window.Kakao.API.request({
            url: '/v2/user/me',
            data: {
                property_keys: ['kakao_account.email', 'kakao_account.profile', 'properties.nickname']
            },
            success: (res) => {
                if (res.kakao_account.email_needs_agreement) {
                    alert('사용자 식별 및 필요한 경우 연락드리기 위해 이메일 주소가 필요합니다. 이메일 주소 제공에 동의해주세요!')
                    return false
                }
                this.setState({
                    id: res.id,
                    name: (res.properties && res.properties.nickname) ? res.properties.nickname : 'noname',
                    email: res.kakao_account.email,
                });
                this.props.save_profile({
                    access_token: this.state.access_token,
                    id: res.id,
                    name: (res.properties && res.properties.nickname) ? res.properties.nickname : 'noname',
                    email: res.kakao_account.email,
                });
                if (dummy === 'true' && token) {
                    fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                        method: 'put',
                        headers: {
                            'Content-Type':'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            newEmail: res.kakao_account.email, 
                            newKakaoId: res.id, 
                            kakaoAccessToken: this.state.access_token,
                            kakaoRefreshToken: this.state.refresh_token,
                        })
                    })
                    .then((res) => res.json())
                    .then((response) => {
                        // save the new token from response.token
                        const new_token = response.token
                        this.props.save_token(new_token);
                        localStorage.setItem('dummy', false);
                        this.setState({
                            dummy: false
                        })
                    }, (error) => {
                        if (error) {
                            console.log(error);
                        }
                    })
                } else {
                    fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                        method: 'post',
                        headers: {'Content-Type':'application/json'},
                        body: JSON.stringify({
                            email: res.kakao_account.email, 
                            kakaoId: res.id, 
                            kakaoAccessToken: this.state.access_token,
                            kakaoRefreshToken: this.state.refresh_token,
                        })
                    })
                        .then((res) => res.json())
                        .then((response) => {
                            // console.log(response)
                            this.props.save_token(response.token);
                            if (response.user.consentAgreed && response.user.numOpinions && response.user.numOpinions >= 6) {
                                this.props.history.push('/NewComplete')
                            } else if (response.user.consentAgreed) {
                                this.props.history.push('/AnswerIssue')
                            } else {
                                this.props.history.push('/consent')
                            }
    
                        }, (error) => {
                            if (error) {
                                console.log(error);
                            }
                        })
                }
            },
            fail: function(error) {
                console.log(error);
            }
        })
    }

    componentDidMount() {
        // alert(navigator.doNotTrack === undefined && navigator.msDoNotTrack === undefined && window.doNotTrack === undefined)
        // // console.log(process.env.REACT_APP_URL);
        // alert(navigator.userAgent.includes('wv'))
    }

    render() {
        return (
            <div className="wrapper">
                {/* <Header /> */}
                <EventDialog></EventDialog>
                <div className="title welcome-box text-center text-light text-sm-spacing">
                    <img src={logo} alt="별별생각" height="100%"></img>
                    <div>서로 다른 생각이 빛나는 곳</div>
                    <br />
                </div>
                <Button type="button-gradient sm" content="익명으로 의견 남기기" onClick={() => this.proceed()}/>
                <Button type="sm button-login" content="카카오 계정으로 의견 남기기" onClick={() => this.login()}/>
                <div className="text-center underline about-link">
                    <a href="/about">
                        별별생각은...
                    </a>
                </div>
                <div className="logos">
                    <div className="col first">
                        <img src={kaist} className="logo" alt="KAIST logo"></img>
                        <img src={kixlab} className="logo" alt="KAIST Interaction Lab"></img>
                    </div>

                    <div className="col second">
                        <img src={sbs} className="logo sbs" alt="SBS logo"></img>
                        <img src={sdflogo} className="logo sbs" alt="SDF Logo"></img>
                    </div>
                </div>

            </div>
        )
    }
}

HomeScreen.propTypes = {
    isMobile: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);