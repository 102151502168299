import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Button from '../../components/Button';
import { saveOpinion, saveProfile, saveAPIToken } from '../../actions';

const mapStateToProps = (state) => {
    const { setMediaFilter, selectOption, saveIssueKey, saveAPIToken } = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        option: selectOption,
        issueKey: saveIssueKey,
        token: saveAPIToken,
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        opinion: (opinion) => dispatch(saveOpinion(opinion)),
        save_profile: (profile) => dispatch(saveProfile(profile)),
        save_token: (token) => dispatch(saveAPIToken(token))
    }
}

class CompleteScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: false
        }
        this.login = this.login.bind(this);
        this.successHandler = this.successHandler.bind(this);
    }

    componentDidMount() {
        const dummy = localStorage.getItem('dummy');
        this.setState({
            dummy: dummy
        })
    }

    login() {
        window.Kakao.Auth.login({
            scope: 'account_email',
            success: (res) => {
                console.log(res);
                this.successHandler(res)
            },
            fail: function(error) {
                console.log(error);
            }
          });
    }

    successHandler(res) {
        const { issueKey, token } = this.props;
        this.setState({
            access_token: res.access_token,
            refresh_token: res.refresh_token,
        });
        window.Kakao.API.request({
            url: '/v2/user/me',
            data: {
                property_keys: ['kakao_account.email', 'kakao_account.profile', 'properties.nickname']
            },
            success: (res) => {
                this.setState({
                    id: res.id,
                    name: (res.properties && res.properties.nickname) ? res.properties.nickname : 'noname',
                    email: res.kakao_account.email,
                });
                this.props.save_profile({
                    access_token: this.state.access_token,
                    id: res.id,
                    name: (res.properties && res.properties.nickname) ? res.properties.nickname : 'noname',
                    email: res.kakao_account.email,
                });
                fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                    method: 'put',
                    headers: {
                        'Content-Type':'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        newEmail: res.kakao_account.email, 
                        newKakaoId: res.id, 
                        kakaoAccessToken: this.state.access_token,
                        kakaoRefreshToken: this.state.refresh_token,
                        consentAgreed: true
                        // age: users.age,
                        // gender: users.gender,
                        // occupation: users.occupation,
                        // politicalStance: users.politicalStance
                    }) // send age, gender, occupation and politicalStance together to reduce one API call
                })
                .then((res) => res.json())
                .then((response) => {
                    // save the new token from response.token
                    const new_token = response.token
                    this.props.save_token(new_token);
                    localStorage.setItem('dummy', false);

                    // // save user info (if it is used in the system) from response.user
                    console.log(response);
                    // const users = localStorage.getItem('users');
                    // const opinions = localStorage.getItem('opinions');
                    // if( users ) {
                    //     fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                    //         method: 'put',
                    //         // credentials: 'include',
                    //         headers: {
                    //             'Content-Type': 'application/json',
                    //             'Authorization': `Bearer ${new_token}`
                    //         },
                    //         body: JSON.stringify(users)
                    //     })
                    // }
                    // if ( opinions ) {
                    //     fetch(`${process.env.REACT_APP_URL}/dev/opinions`, {
                    //         method: 'post',
                    //         headers: {
                    //             'Content-Type': 'application/json',
                    //             'Authorization': `Bearer ${new_token}`
                    //         },
                    //         body: JSON.stringify(opinions)
                    //     })
                    //         .then((res) => res.json())
                    //         .then((response) => {
                    //             console.log(response);
                    //             opinion(response._id);
                    //         })  
                    // }
                    this.props.history.push(`/result/${issueKey}`)
                }, (error) => {
                    if (error) {
                        console.log(error);
                    }
                })
            },
            fail: function(error) {
                console.log(error);
            }
        })
    }
    render() {
        const { isMobile, issueKey } = this.props;
        const { dummy } = this.state;
        return (
            <div className="wrapper">
                <Header />
                <div className="container">
                    <div className="title text-center complete">
                        응답해주셔서 감사합니다.
                        <br></br>
                        {dummy === 'true' ? '카카오 로그인을 하시면 응답 결과가 저장되고 맞춤형 결과를 보실 수 있습니다.'
                        : '' }
                    </div>
                </div>
                {
                    dummy === 'true' ? 
                    
                    <Button type="button-gradient button sm" content={"카카오 로그인 후 결과 보기"} onClick={() => this.login()}/> :
                    null
                }
                {
                    isMobile ? 
                    <div className="next">
                        <Button type="button-transparent button sm" content={dummy === 'true' ? '그냥 결과보기' : '결과보기'} onClick={() => this.props.history.push(`/result/${issueKey}`)}/>
                    </div>
                    :
                    <Button type="button-transparent button sm" content={dummy === 'true' ? '그냥 결과보기' : '결과보기'} onClick={() => this.props.history.push(`/result/${issueKey}`)}/>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteScreen);